import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Listing from "../components/Listing"
import Seo from "../components/Seo"

export default function Section({ data }) {
  //Data destructuring
  const articlesData = data.articles.edges.map(edge => edge.node)
  const sectionTitle = data.section.edges[0].node.title
  const seo = data.seo

  return (
    <Layout>
      <Seo seoData={seo} />
      {/* Header */}
      <section className="section-header">
        <h1 className="section-title">
          <span className="russian" lang="ru">
            {sectionTitle.ru}
          </span>
          <span className="finnish" lang="fi">
            {sectionTitle.fi}
          </span>
        </h1>
      </section>

      {/* Articles Listing */}
      <Listing data={articlesData} pagesPerLoad={24} />
    </Layout>
  )
}

export const query = graphql`
  query GetSection($id: String) {
    section: allSanitySection(filter: { _id: { eq: $id } }) {
      edges {
        node {
          title {
            fi
            ru
          }
        }
      }
    }
    articles: allSanityArticle(
      filter: { section: { _id: { eq: $id } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          _id
          _type
          slug {
            current
          }
          date
          cover {
            asset {
              _id
            }
          }
          thumb {
            asset {
              _id
            }
          }
          title {
            fi
            ru
          }
          description {
            fi
            ru
          }
          author {
            name {
              ru
              fi
            }
          }
          section {
            title {
              ru
              fi
            }
            slug {
              current
            }
          }
        }
      }
    }
    seo: sanitySection(_id: { eq: $id }) {
      _id
      _type
      title {
        fi
        ru
      }
      slug {
        current
      }
      seo {
        metaTitle
        metaDescription
        metaKeywords
        sharedImage {
          asset {
            _id
          }
        }
        preventIndexing
      }
    }
  }
`
